<template>
  <div>
    <SpinnerLoader v-if="salesPlanExecutionsStatus !== 'success'" :loading="salesPlanExecutionsStatus" />
    <template v-else-if="salesPlanExecutionsStatus === 'success' && salesPlanExecutions.data && salesPlanExecutions.data[selectedTab]">
      <div class="table-responsive mb-0 sales-plan-executions">
          <table class="table table-sm small table-hover table-bordered ws-nowrap sticky-headers sticky-footer">
            <thead>
              <tr>
                <th rowspan="2">
                  <div class="resizable period">{{ $t('table.period') }}</div>
                </th>
                <th rowspan="2" name="prime">{{ $t('table.time') }}</th>
                <th colspan="3">wGRP {{ $t('table.open') }}</th>
                <th colspan="3">wGRP {{ $t('reports.soldOut') }}</th>
                <th colspan="3">SOR %</th>
                <th colspan="3">CPP</th>
                <th colspan="3">{{ $t('table.budget') }}</th>
              </tr>
              <tr>
                <th class="text-right">{{ $t('table.commitment') }}</th>
                <th class="text-right">{{ $t('table.plan') }}</th>
                <th class="text-right">{{ $t('table.plan') }}+{{ $t('table.fact') }}</th>

                <th class="text-right">{{ $t('table.commitment') }}</th>
                <th class="text-right">{{ $t('reports.cp') }}</th>
                <th class="text-right">{{ $t('table.plan') }}+{{ $t('table.fact') }}</th>

                <th class="text-right">{{ $t('table.commitment') }}</th>
                <th class="text-right">{{ $t('reports.cp') }}</th>
                <th class="text-right">{{ $t('table.plan') }}+{{ $t('table.fact') }}</th>

                <th class="text-right">{{ $t('table.commitment') }}</th>
                <th class="text-right">{{ $t('reports.cp') }}</th>
                <th class="text-right">{{ $t('table.plan') }}+{{ $t('table.fact') }}</th>

                <th class="text-right">{{ $t('table.commitment') }}</th>
                <th class="text-right">{{ $t('reports.cp') }}</th>
                <th class="text-right">{{ $t('table.plan') }}+{{ $t('table.fact') }}</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, index) in salesPlanExecutions.data[selectedTab].items">
                <tr v-if="!show_only_monthly_totals" :key="index + '_1'">
                  <td name="period">{{ item.month ? months[+item.month - 1] : '' }}</td>
                  <td>{{ $t('channelModal.prime') }}</td>

                  <td class="text-right">{{ item.prime_commitment_wgrp_open | toFixedAndSpace }}</td>
                  <td class="text-right">{{ item.prime_plan_wgrp_open | toFixedAndSpace }}</td>
                  <td class="text-right">{{ item.prime_plan_fact_wgrp_open | toFixedAndSpace }}</td>

                  <td class="text-right">{{ item.prime_commitment_wgrp_sold | toFixedAndSpace }}</td>
                  <td class="text-right">{{ item.prime_plan_wgrp_sold | toFixedAndSpace }}</td>
                  <td class="text-right">{{ item.prime_plan_fact_wgrp_sold | toFixedAndSpace }}</td>

                  <td class="text-right">{{ (+item.prime_commitment_sor).toFixed(2) }}%</td>
                  <td class="text-right">{{ (+item.prime_plan_sor).toFixed(2) }}%</td>
                  <td class="text-right">{{ (+item.prime_plan_fact_sor).toFixed(2) }}%</td>

                  <td class="text-right">{{ item.prime_commitment_cpp | toFixedAndSpace }}</td>
                  <td class="text-right">{{ item.prime_plan_cpp | toFixedAndSpace }}</td>
                  <td class="text-right">{{ item.prime_plan_fact_cpp | toFixedAndSpace }}</td>

                  <td class="text-right">{{ item.prime_commitment_budget | toFixedAndSpace }}</td>
                  <td class="text-right">{{ item.prime_plan_budget | toFixedAndSpace }}</td>
                  <td class="text-right">{{ item.prime_plan_fact_budget | toFixedAndSpace }}</td>
                </tr>

                <tr v-if="!show_only_monthly_totals" :key="index + '_2'">
                  <td name="period">{{ item.month ? months[+item.month - 1] : '' }}</td>
                  <td>{{ $t('channelModal.offPrime') }}</td>

                  <td class="text-right">{{ item.off_prime_commitment_wgrp_open | toFixedAndSpace }}</td>
                  <td class="text-right">{{ item.off_prime_plan_wgrp_open | toFixedAndSpace }}</td>
                  <td class="text-right">{{ item.off_prime_plan_fact_wgrp_open | toFixedAndSpace }}</td>

                  <td class="text-right">{{ item.off_prime_commitment_wgrp_sold | toFixedAndSpace }}</td>
                  <td class="text-right">{{ item.off_prime_plan_wgrp_sold | toFixedAndSpace }}</td>
                  <td class="text-right">{{ item.off_prime_plan_fact_wgrp_sold | toFixedAndSpace }}</td>

                  <td class="text-right">{{ (+item.off_prime_commitment_sor).toFixed(2) }}%</td>
                  <td class="text-right">{{ (+item.off_prime_plan_sor).toFixed(2) }}%</td>
                  <td class="text-right">{{ (+item.off_prime_plan_fact_sor).toFixed(2) }}%</td>

                  <td class="text-right">{{ item.off_prime_commitment_cpp | toFixedAndSpace }}</td>
                  <td class="text-right">{{ item.off_prime_plan_cpp | toFixedAndSpace }}</td>
                  <td class="text-right">{{ item.off_prime_plan_fact_cpp | toFixedAndSpace }}</td>

                  <td class="text-right">{{ item.off_prime_commitment_budget | toFixedAndSpace }}</td>
                  <td class="text-right">{{ item.off_prime_plan_budget | toFixedAndSpace }}</td>
                  <td class="text-right">{{ item.off_prime_plan_fact_budget | toFixedAndSpace }}</td>
                </tr>

                <tr :key="index + '_3'">
                  <th name="period">{{ item.month ? months[+item.month - 1] : '' }}</th>
                  <th>{{ $t('booking.total') }}</th>

                  <th class="text-right">{{ item.total_commitment_wgrp_open | toFixedAndSpace }}</th>
                  <th class="text-right">{{ item.total_plan_wgrp_open | toFixedAndSpace }}</th>
                  <th class="text-right">{{ item.total_plan_fact_wgrp_open | toFixedAndSpace }}</th>

                  <th class="text-right">{{ item.total_commitment_wgrp_sold | toFixedAndSpace }}</th>
                  <th class="text-right">{{ item.total_plan_wgrp_sold | toFixedAndSpace }}</th>
                  <th class="text-right">{{ item.total_plan_fact_wgrp_sold | toFixedAndSpace }}</th>

                  <th class="text-right">{{ (+item.total_commitment_sor).toFixed(2) }}%</th>
                  <th class="text-right">{{ (+item.total_plan_sor).toFixed(2) }}%</th>
                  <th class="text-right">{{ (+item.total_plan_fact_sor).toFixed(2) }}%</th>

                  <th class="text-right">{{ item.total_commitment_cpp | toFixedAndSpace }}</th>
                  <th class="text-right">{{ item.total_plan_cpp | toFixedAndSpace }}</th>
                  <th class="text-right">{{ item.total_plan_fact_cpp | toFixedAndSpace }}</th>

                  <th class="text-right">{{ item.total_commitment_budget | toFixedAndSpace }}</th>
                  <th class="text-right">{{ item.total_plan_budget | toFixedAndSpace }}</th>
                  <th class="text-right">{{ item.total_plan_fact_budget | toFixedAndSpace }}</th>
                </tr>
              </template>
            </tbody>

            <tfoot v-if="salesPlanExecutions.data[selectedTab].totals" class="font-weight-bold">
              <tr>
                <td name="period" class="text-uppercase">{{ $t('booking.total') }}</td>
                <td>{{ $t('channelModal.prime') }}</td>

                <td class="text-right">{{ salesPlanExecutions.data[selectedTab].totals.prime_commitment_wgrp_open | toFixedAndSpace }}</td>
                <td class="text-right">{{ salesPlanExecutions.data[selectedTab].totals.prime_plan_wgrp_open | toFixedAndSpace }}</td>
                <td class="text-right">{{ salesPlanExecutions.data[selectedTab].totals.prime_plan_fact_wgrp_open | toFixedAndSpace }}</td>

                <td class="text-right">{{ salesPlanExecutions.data[selectedTab].totals.prime_commitment_wgrp_sold | toFixedAndSpace }}</td>
                <td class="text-right">{{ salesPlanExecutions.data[selectedTab].totals.prime_plan_wgrp_sold | toFixedAndSpace }}</td>
                <td class="text-right">{{ salesPlanExecutions.data[selectedTab].totals.prime_plan_fact_wgrp_sold | toFixedAndSpace }}</td>

                <td class="text-right">{{ (+salesPlanExecutions.data[selectedTab].totals.prime_commitment_sor).toFixed(2) }}%</td>
                <td class="text-right">{{ (+salesPlanExecutions.data[selectedTab].totals.prime_plan_sor).toFixed(2) }}%</td>
                <td class="text-right">{{ (+salesPlanExecutions.data[selectedTab].totals.prime_plan_fact_sor).toFixed(2) }}%</td>

                <td class="text-right">{{ salesPlanExecutions.data[selectedTab].totals.prime_commitment_cpp | toFixedAndSpace }}</td>
                <td class="text-right">{{ salesPlanExecutions.data[selectedTab].totals.prime_plan_cpp | toFixedAndSpace }}</td>
                <td class="text-right">{{ salesPlanExecutions.data[selectedTab].totals.prime_plan_fact_cpp | toFixedAndSpace }}</td>

                <td class="text-right">{{ salesPlanExecutions.data[selectedTab].totals.prime_commitment_budget | toFixedAndSpace }}</td>
                <td class="text-right">{{ salesPlanExecutions.data[selectedTab].totals.prime_plan_budget | toFixedAndSpace }}</td>
                <td class="text-right">{{ salesPlanExecutions.data[selectedTab].totals.prime_plan_fact_budget | toFixedAndSpace }}</td>
              </tr>

              <tr>
                <td name="period" class="text-uppercase">{{ $t('booking.total') }}</td>
                <td>{{ $t('channelModal.offPrime') }}</td>

                <td class="text-right">{{ salesPlanExecutions.data[selectedTab].totals.off_prime_commitment_wgrp_open | toFixedAndSpace }}</td>
                <td class="text-right">{{ salesPlanExecutions.data[selectedTab].totals.off_prime_plan_wgrp_open | toFixedAndSpace }}</td>
                <td class="text-right">{{ salesPlanExecutions.data[selectedTab].totals.off_prime_plan_fact_wgrp_open | toFixedAndSpace }}</td>

                <td class="text-right">{{ salesPlanExecutions.data[selectedTab].totals.off_prime_commitment_wgrp_sold | toFixedAndSpace }}</td>
                <td class="text-right">{{ salesPlanExecutions.data[selectedTab].totals.off_prime_plan_wgrp_sold | toFixedAndSpace }}</td>
                <td class="text-right">{{ salesPlanExecutions.data[selectedTab].totals.off_prime_plan_fact_wgrp_sold | toFixedAndSpace }}</td>

                <td class="text-right">{{ (+salesPlanExecutions.data[selectedTab].totals.off_prime_commitment_sor).toFixed(2) }}%</td>
                <td class="text-right">{{ (+salesPlanExecutions.data[selectedTab].totals.off_prime_plan_sor).toFixed(2) }}%</td>
                <td class="text-right">{{ (+salesPlanExecutions.data[selectedTab].totals.off_prime_plan_fact_sor).toFixed(2) }}%</td>

                <td class="text-right">{{ salesPlanExecutions.data[selectedTab].totals.off_prime_commitment_cpp | toFixedAndSpace }}</td>
                <td class="text-right">{{ salesPlanExecutions.data[selectedTab].totals.off_prime_plan_cpp | toFixedAndSpace }}</td>
                <td class="text-right">{{ salesPlanExecutions.data[selectedTab].totals.off_prime_plan_fact_cpp | toFixedAndSpace }}</td>

                <td class="text-right">{{ salesPlanExecutions.data[selectedTab].totals.off_prime_commitment_budget | toFixedAndSpace }}</td>
                <td class="text-right">{{ salesPlanExecutions.data[selectedTab].totals.off_prime_plan_budget | toFixedAndSpace }}</td>
                <td class="text-right">{{ salesPlanExecutions.data[selectedTab].totals.off_prime_plan_fact_budget | toFixedAndSpace }}</td>
              </tr>

              <tr>
                <th name="period" class="text-uppercase">{{ $t('booking.total') }}</th>
                <th>{{ $t('booking.total') }}</th>

                <th class="text-right">{{ salesPlanExecutions.data[selectedTab].totals.total_commitment_wgrp_open | toFixedAndSpace }}</th>
                <th class="text-right">{{ salesPlanExecutions.data[selectedTab].totals.total_plan_wgrp_open | toFixedAndSpace }}</th>
                <th class="text-right">{{ salesPlanExecutions.data[selectedTab].totals.total_plan_fact_wgrp_open | toFixedAndSpace }}</th>

                <th class="text-right">{{ salesPlanExecutions.data[selectedTab].totals.total_commitment_wgrp_sold | toFixedAndSpace }}</th>
                <th class="text-right">{{ salesPlanExecutions.data[selectedTab].totals.total_plan_wgrp_sold | toFixedAndSpace }}</th>
                <th class="text-right">{{ salesPlanExecutions.data[selectedTab].totals.total_plan_fact_wgrp_sold | toFixedAndSpace }}</th>

                <th class="text-right">{{ (+salesPlanExecutions.data[selectedTab].totals.total_commitment_sor).toFixed(2) }}%</th>
                <th class="text-right">{{ (+salesPlanExecutions.data[selectedTab].totals.total_plan_sor).toFixed(2) }}%</th>
                <th class="text-right">{{ (+salesPlanExecutions.data[selectedTab].totals.total_plan_fact_sor).toFixed(2) }}%</th>

                <th class="text-right">{{ salesPlanExecutions.data[selectedTab].totals.total_commitment_cpp | toFixedAndSpace }}</th>
                <th class="text-right">{{ salesPlanExecutions.data[selectedTab].totals.total_plan_cpp | toFixedAndSpace }}</th>
                <th class="text-right">{{ salesPlanExecutions.data[selectedTab].totals.total_plan_fact_cpp | toFixedAndSpace }}</th>

                <th class="text-right">{{ salesPlanExecutions.data[selectedTab].totals.total_commitment_budget | toFixedAndSpace }}</th>
                <th class="text-right">{{ salesPlanExecutions.data[selectedTab].totals.total_plan_budget | toFixedAndSpace }}</th>
                <th class="text-right">{{ salesPlanExecutions.data[selectedTab].totals.total_plan_fact_budget | toFixedAndSpace }}</th>
              </tr>
              <tr v-if="tabsCount > 1">
                <th colspan="14" class="text-uppercase">{{ $t('booking.totalAllChannels') }}</th>
                <th class="text-right">{{ salesPlanExecutions.totals.total_commitment_budget | toFixedAndSpace }}</th>
                <th class="text-right">{{ salesPlanExecutions.totals.total_plan_budget | toFixedAndSpace }}</th>
                <th class="text-right">{{ salesPlanExecutions.totals.total_plan_fact_budget | toFixedAndSpace }}</th>
              </tr>
              <tr v-if="tabsCount > 1">
                <td colspan="17">
                  <div class="d-flex px-3 py-2 overflow-auto">
                    <b-button v-for="(item, name) in salesPlanExecutions.data" :key="name" size="sm" :variant="selectedTab === name ? 'primary' : 'link'" @click="changeActiveTab(name)">
                      {{ name }}
                    </b-button>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SpinnerLoader from '@/components/SpinnerLoader';
import toFixedAndSpace from '@/filters/toFixedAndSpace';

export default {
  name: 'SalesPlanExecutionsTable',
  components: { SpinnerLoader },
  filters: { toFixedAndSpace },
  props: {
    show_only_monthly_totals: { type: Boolean, default: false },
  },
  data() {
    return {
      selectedTab: '',
    };
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
      salesPlanExecutions: 'getReportSalesPlanExecutions',
      salesPlanExecutionsStatus: 'getReportSalesPlanExecutionsStatus',
    }),
    tabsCount() {
      if (this.salesPlanExecutionsStatus === 'success' && this.salesPlanExecutions) {
        return Object.keys(this.salesPlanExecutions.data).length;
      } else return 1;
    },
    months() {
      return [
        this.$i18n.t('month.jan'),
        this.$i18n.t('month.feb'),
        this.$i18n.t('month.mar'),
        this.$i18n.t('month.apr'),
        this.$i18n.t('month.may'),
        this.$i18n.t('month.jun'),
        this.$i18n.t('month.jul'),
        this.$i18n.t('month.aug'),
        this.$i18n.t('month.sep'),
        this.$i18n.t('month.oct'),
        this.$i18n.t('month.nov'),
        this.$i18n.t('month.dec'),
      ];
    },
  },
  watch: {
    salesPlanExecutions() {
      console.log(this.salesPlanExecutions, this.salesPlanExecutionsStatus);
      if (this.salesPlanExecutionsStatus === 'success' && this.salesPlanExecutions.data) {
        this.selectedTab = Object.keys(this.salesPlanExecutions.data)[0];
      }
    },
    selectedTab() {
      // scroll to the top of wrapper on tab change
      const tableWrapper = document.querySelector('div.sales-plan-executions');
      if (tableWrapper) tableWrapper.scrollTop = 0;
    },
  },
  destroyed() {
    this.$store.commit('clearReportSalesPlanExecutions');
  },
  methods: {
    changeActiveTab(name) {
      this.selectedTab = name;
    },
  },
};
</script>
<style lang="sass">
.reports-page div.table-responsive.sales-plan-executions
  height: calc(100vh - 155px)

.reports-page .sales-plan-executions table.table
  thead th[name="prime"]
    min-width: 75px

  div.resizable.period
    min-width: 50px

  td[name="period"]
    max-width: 50px

@media (max-width: 1515px)
  .reports-page div.table-responsive.sales-plan-executions
    height: calc(100vh - 245px)
</style>
